.portraitContainer {
    width: 100%;

    .portrait {
        border: 5px solid goldenrod
    }

    input {
        border: 5px solid goldenrod;
        margin: 5px auto;
        display: block;
    }
}